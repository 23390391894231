import { useEffect, useRef, useState } from "react";
import { baseApi } from "./axios";
import { AxiosError, AxiosRequestConfig } from "axios";
import { useSearchParams } from "react-router-dom";

type Props = {
  url: string;
  params?: AxiosRequestConfig["params"];
};

type QueryReturns<Response> = {
  data: Response | null;
  isLoading: boolean;
  error: AxiosError | null;
};

type QueryHook = <Response>(props: Props) => QueryReturns<Response>;

export const useQuery: QueryHook = ({ url, params }) => {
  const [queryParams] = useSearchParams();

  const [result, setResult] = useState({
    data: null,
    isLoading: false,
    error: null,
  });

  const shouldFetch = useRef(true);

  useEffect(() => {
    if (!shouldFetch.current) return;

    setResult((p) => ({ ...p, isLoading: true }));

    baseApi(url, { params: { access_token: queryParams.get("access_token"), ...params } })
      .then(({ data }) => setResult((p) => ({ ...p, data, error: null })))
      .catch((error) => setResult((p) => ({ ...p, data: null, error })))
      .finally(() => {
        setResult((p) => ({ ...p, isLoading: false }));
      });

    shouldFetch.current = false;
  }, [url, params, queryParams]);

  return result;
};

type MutationReturns<Request, Response> = {
  data: Response | null;
  isLoading: boolean;
  error: AxiosError | null;
  mutate: (data: Request, onSuccess?: any, onError?: any) => void;
  mutateForm: (data: FormData) => Promise<any>;
};

type MutationHook = <Request, Response>(props: Props) => MutationReturns<Request, Response>;

export const useMutation: MutationHook = ({ url, params }) => {
  const [queryParams] = useSearchParams();

  const [result, setResult] = useState({
    data: null,
    isLoading: false,
    error: null,
  });

  const mutate = (data: any, onSuccess?: any, onError?: any) => {
    setResult((p) => ({ ...p, isLoading: true }));

    baseApi(url, {
      params: { access_token: queryParams.get("access_token"), ...params },
      method: "post",
      data,
    })
      .then(({ data }) => {
        setResult((p) => ({ ...p, data, error: null }));
        onSuccess?.();
      })
      .catch((error) => {
        setResult((p) => ({ ...p, data: null, error }));
        onError?.();
      })
      .finally(() => {
        setResult((p) => ({ ...p, isLoading: false }));
      });
  };

  const mutateForm = (data: any) => {
    return baseApi(url, {
      params: { access_token: queryParams.get("access_token"), ...params },
      method: "post",
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  return { ...result, mutate, mutateForm };
};
