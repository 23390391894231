declare global {
  interface FileList {
    map<T>(callback: (f: File) => T): T[];
    filter(callback: (f: File) => boolean): void;
    forEach(callback: (f: File) => void): void;
  }
}

FileList.prototype.map = Array.prototype.map;
FileList.prototype.filter = Array.prototype.filter;
FileList.prototype.forEach = Array.prototype.forEach;

export {};
