import cn from "classnames";
import { FC, HTMLAttributes } from "react";
import emptyAvatar from "shared/assets/icons/avatar-empty.svg";

type Props = {
  src?: string;
} & HTMLAttributes<HTMLImageElement>;

export const Avatar: FC<Props> = ({ src, className, ...props }) => {
  return (
    <img
      {...props}
      className={cn(className, "w-[48px] h-[48px] rounded-full object-cover")}
      src={src ?? emptyAvatar}
      alt="avatar"
    />
  );
};
