import { Avatar } from "shared/ui/avatar";
import { Card } from "shared/ui/card";
import { Rating } from "shared/ui/rating";
import emptyAvatar from "shared/assets/icons/avatar-empty.svg";
import { useQuery } from "shared/services/hooks";
import { TIssueResponse } from "shared/types/issue";
import { Skeleton } from "shared/ui/skeleton";
import { Tag } from "shared/ui/tag";
import { Preview } from "features/preview";
import ScrollContainer from "react-indiana-drag-scroll";
import { Title } from "shared/ui/typography";
import { Navigate } from "react-router-dom";

export const View = () => {
  const { data, isLoading, error } = useQuery<TIssueResponse>({
    url: "/v2/unauth/issues",
  });

  const issue = data?.issue;

  const files = [...(issue?.feedback.photo_files || []), ...(issue?.feedback.video_files || [])];

  if (error)
    return <Navigate to={`error?error=${(error?.response?.data as any)?.error?.message}`} />;

  return (
    <div className="pt-[20px] md:pt-[50px]">
      <Card className="text-center">
        {isLoading ? (
          <Skeleton>
            <Title>Отзыв по заявке №3333</Title>
          </Skeleton>
        ) : (
          <Title>Отзыв по заявке №{issue?.oid}</Title>
        )}
      </Card>

      <Card className="flex flex-col gap-[15px] items-center mt-[20px]">
        <div className="flex items-center justify-center gap-[15px] w-full">
          <Avatar src={issue?.worker.profile_photo_file.url} />
          <span className="text-[14px] md:text-[20px] font-medium">Оцените работу мастера</span>
        </div>
        <Rating value={issue?.feedback.rating_master} />

        <div className="flex items-center justify-center gap-[15px] w-full">
          <Avatar src={emptyAvatar} />
          <span className="text-[14px] md:text-[20px] font-medium">Оцените работу сервиса</span>
        </div>
        <Rating value={issue?.feedback.rating_call_center} />

        {issue?.feedback.reasons.length ? (
          <div className="w-full">
            <span className="text-[14px] md:text-[16px] font-medium">Что мы можем улучшить</span>
            <div className="flex flex-wrap gap-[10px] mt-[10px]">
              {issue?.feedback.reasons.map((item) => (
                <Tag key={item.id}>{item.name}</Tag>
              ))}
            </div>
          </div>
        ) : null}

        {issue?.feedback.comment && (
          <div className="w-full">
            <span className="text-[14px] md:text-[16px] font-medium">
              Комментарии и предложения
            </span>
            <div>{issue?.feedback.comment}</div>
          </div>
        )}

        {files.length ? (
          <div className="w-full">
            <span className="text-[14px] md:text-[16px] font-medium">Прикрепленные файлы</span>
            <ScrollContainer
              className="w-full scroll-container py-[8px] mt-[10px]"
              hideScrollbars={false}
            >
              <div className="flex gap-[10px]">
                {files.map((item) => (
                  <Preview
                    key={item.url}
                    src={item.url}
                    type={item.mimetype.match("image") ? "image" : "video"}
                  />
                ))}
              </div>
            </ScrollContainer>
          </div>
        ) : null}
      </Card>
    </div>
  );
};
