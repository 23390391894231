import { RouterProvider } from "react-router-dom";
import { Layout } from "./layout";

import "./config";
import { router } from "./routes";

export const App = () => {
  return (
    <Layout>
      <RouterProvider router={router} />
    </Layout>
  );
};
