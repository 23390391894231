import { useSearchParams } from "react-router-dom";
import { Card } from "shared/ui/card";
import { Title } from "shared/ui/typography";

export const Error = () => {
  const [params] = useSearchParams();
  return (
    <div className="pt-[50px]">
      <Card className="text-center">
        <Title>Извините, что-то пошло не так</Title>
        <span className="mt-[20px] text-[12px] md:text-[16px] font-medium">
          {params.get("error") || "Подождите немного и попробуйте снова"}
        </span>
      </Card>
    </div>
  );
};
