import cn from "classnames";
import { FC, memo, useState } from "react";
import remove from "shared/assets/icons/remove.svg";
import play from "shared/assets/icons/play.svg";
import { styles } from "./styles";
import { Overlay } from "shared/ui/overlay";

type Props = {
  file?: File;
  src?: string;
  type?: "video" | "image";
  onRemoveClick?: (file: File) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const Preview: FC<Props> = memo(
  ({ src, type, file, className, onRemoveClick, ...props }) => {
    const fileSrc = file && URL.createObjectURL(file);

    const [overlay, setOverlay] = useState(false);

    const isVideo = file?.type.match("video") || type === "video";
    const isImage = file?.type.match("image") || type === "image";

    return (
      <div className={cn(className, styles.main)} {...props}>
        {isVideo && (
          <>
            <video
              onClick={() => setOverlay(true)}
              className={styles.preview}
              src={src ?? fileSrc}
            />
            <img onClick={() => setOverlay(true)} className={styles.play} src={play} alt="icon" />
            {overlay && (
              <Overlay
                onOverlayClick={() => {
                  setOverlay(false);
                }}
              >
                <div className="w-full max-w-[600px] rounded-[8px] overflow-hidden">
                  <video src={src ?? fileSrc} controls />
                </div>
              </Overlay>
            )}
          </>
        )}
        {isImage && (
          <>
            <img
              onClick={() => setOverlay(true)}
              className={styles.preview}
              src={src ?? fileSrc}
              alt="preview"
            />
            {overlay && (
              <Overlay
                onOverlayClick={() => {
                  setOverlay(false);
                }}
              >
                <div className="w-full max-w-[600px] rounded-[8px] overflow-hidden">
                  <img src={src ?? fileSrc} alt="preview" />
                </div>
              </Overlay>
            )}
          </>
        )}
        {file && (
          <img
            onClick={() => onRemoveClick?.(file)}
            className={styles.remove}
            src={remove}
            alt="icon"
          />
        )}
      </div>
    );
  }
);
