import cn from "classnames";
import { FC } from "react";

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const Textarea: FC<Props> = ({ className, rows, ...props }) => {
  return (
    <textarea
      {...props}
      className={cn(
        className,
        "w-full border-none rounded-[8px] resize-none overflow-hidden bg-[#F5F7FC] placeholder:text-[#232D42] text-[10px] md:text-[15px]"
      )}
      rows={rows || 4}
    />
  );
};
