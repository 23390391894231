import { FC, PropsWithChildren } from "react";
import logo from "shared/assets/icons/logo.svg";
import bg from "shared/assets/icons/main-bg.svg";

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <img className="fixed bottom-0 left-0 z-1" src={bg} alt="bg" />
      <div className="bg-grey min-h-screen">
        <div className="flex justify-center items-center gap-[10px] bg-white h-[79px]">
          <img src={logo} alt="logo" />
          <span className="text-[33px] font-inter font-medium">mytech</span>
        </div>
        <div className="flex justify-center px-[10px] pb-[30px] relative z-2">
          <div className="w-full max-w-[520px]">{children}</div>
        </div>
      </div>
    </>
  );
};
