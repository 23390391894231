import { createBrowserRouter } from "react-router-dom";

import { FinalFullRate, FinalLowRate, FinalMediumRate } from "pages/final";
import { Error } from "pages/error";
import { View } from "pages/view";
import { Main } from "pages/main";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/view",
    element: <View />,
  },
  {
    path: "/finalFullRate",
    element: <FinalFullRate />,
  },
  {
    path: "/finalMediumRate",
    element: <FinalMediumRate />,
  },
  {
    path: "/finalLowRate",
    element: <FinalLowRate />,
  },
  {
    path: "/error",
    element: <Error />,
  },
  {
    path: "/*",
    element: <Main />,
  },
]);
