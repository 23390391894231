import { FC, PropsWithChildren } from "react";

export const Skeleton: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div role="status" className="max-w-sm animate-pulse">
      <div className="bg-gray-200 rounded-[8px] dark:bg-gray-600">
        <span className="opacity-0">{children}</span>
      </div>
    </div>
  );
};
