import { FC, PropsWithChildren } from "react";
import { Button } from "shared/ui/button";
import { Card } from "shared/ui/card";
import { Title } from "shared/ui/typography";

export const FinalLayout: FC<PropsWithChildren<{ isButton?: boolean }>> = ({
  children,
  isButton,
}) => {
  return (
    <div className="pt-[50px]">
      <Card className="text-center">
        <Title>Спасибо за отзыв!</Title>
      </Card>

      <Card className="mt-[20px] text-[12px] md:text-[16px] font-medium">{children}</Card>

      {isButton && (
        <a href="https://redirect.appmetrica.yandex.com/serve/317029172360798790">
          <Button className="mt-[20px] w-full max-w-[360px] mx-auto block">
            Оставить отзыв на Яндекс Картах
          </Button>
        </a>
      )}
    </div>
  );
};

export const FinalFullRate = () => {
  return (
    <FinalLayout isButton>
      Нам приятно, что вы остались довольны. Будем рады видеть вас снова.
    </FinalLayout>
  );
};

export const FinalMediumRate = () => {
  return (
    <FinalLayout isButton>
      Ваши оценки уже переданы в отдел качества. Мы обязательно все проверим, чтобы в следующий раз
      ваши впечатления были еще лучше.
    </FinalLayout>
  );
};

export const FinalLowRate = () => {
  return (
    <FinalLayout>
      Ваши оценки уже переданы в отдел качества. Мы проверим информацию по вашей заявке и
      обязательно исправимся.
    </FinalLayout>
  );
};
