import { Avatar } from "shared/ui/avatar";
import { Card } from "shared/ui/card";
import { Rating } from "shared/ui/rating";
import emptyAvatar from "shared/assets/icons/avatar-empty.svg";
import { Button } from "shared/ui/button";
import { useMutation, useQuery } from "shared/services/hooks";
import { TIssueResponse, TReason } from "shared/types/issue";
import { Skeleton } from "shared/ui/skeleton";
import { useCallback, useState } from "react";
import { Tag } from "shared/ui/tag";
import { Textarea } from "shared/ui/textarea";
import camera from "shared/assets/icons/camera.svg";
import { Preview } from "features/preview";
import ScrollContainer from "react-indiana-drag-scroll";
import { TFeedbackRequest, TFeedbackResponse } from "shared/types/feedback";
import { Navigate, useNavigate } from "react-router-dom";
import { Title } from "shared/ui/typography";
import cn from "classnames";

export const Main = () => {
  const navigate = useNavigate();

  const { data, isLoading, error } = useQuery<TIssueResponse>({
    url: "/v2/unauth/issues",
  });

  const { mutate, isLoading: isFeedbackLoading } = useMutation<TFeedbackRequest, TFeedbackResponse>(
    {
      url: "/v2/unauth/issues/feedback",
    }
  );

  const { mutateForm } = useMutation<TFeedbackRequest, TFeedbackResponse>({
    url: "/v2/unauth/files",
  });

  const [isFilesLoading, setIsFilesLoading] = useState(false);

  const [files, setFiles] = useState<FileList | null>(null);

  const [comment, setComment] = useState("");

  const [workerStars, setWorkerStars] = useState(0);
  const [serviceStars, setServiceStars] = useState(0);

  const issue = data?.issue;

  const workerReasons = data?.feedback_reasons.filter((item) => {
    if (item.tag_type === "worker") {
      if (workerStars === 5 || workerStars === 0) return false;
      else if (workerStars > 3 && serviceStars > 3) return item.min_rating > 3;
      else if (workerStars > 3 && serviceStars <= 4) return false;
      else if (workerStars && workerStars <= 3) return item.max_rating <= 3;
      else return true;
    } else return false;
  });

  const serviceReasons = data?.feedback_reasons.filter((item) => {
    if (item.tag_type === "call_center") {
      if (serviceStars === 5 || serviceStars === 0) return false;
      else if (serviceStars > 3 && workerStars > 3) return item.min_rating > 3;
      else if (serviceStars > 3 && workerStars <= 4) return false;
      else if (serviceStars && serviceStars <= 3) return item.max_rating <= 3;
      else return true;
    } else return false;
  });

  const reasons = [...(workerReasons || []), ...(serviceReasons || [])];

  const [activeReasons, setActiveReasons] = useState<TReason[]>([]);

  const handleTagClick = (item: TReason) => {
    setActiveReasons((prev) => {
      if (prev.find((prevItem) => prevItem.id === item.id)) {
        return prev.filter((prevItem) => prevItem.id !== item.id);
      } else {
        return [...prev, item];
      }
    });
  };

  const handleRemoveImgClick = useCallback((clickedFile: File) => {
    setFiles((prev) => {
      return prev?.filter((item) => item.name !== clickedFile.name) ?? ([] as unknown as FileList);
    });
  }, []);

  const handleSend = () => {
    const data = {
      comment,
      reasons: activeReasons.map((item) => item.id),
      rating_call_center: serviceStars,
      rating_master: workerStars,
    };

    const handleSuccess = () => {
      const path =
        (workerStars && workerStars <= 3) || (serviceStars && serviceStars <= 3)
          ? "/finalLowRate"
          : (workerStars && workerStars === 4) || (serviceStars && serviceStars === 4)
          ? "/finalMediumRate"
          : "/finalFullRate";

      navigate(path);
    };

    if (files && files.length) {
      setIsFilesLoading(true);

      const video_files: string[] = [];
      const photo_files: string[] = [];

      const promises = files.map((item) => {
        let formData = new FormData();

        formData.append("file", item);

        return mutateForm(formData);
      });

      Promise.all(promises)
        .then((res) => {
          res.forEach(({ data }) => {
            if (data.mimetype.match("video")) {
              video_files.push(data.id);
            }
            if (data.mimetype.match("image")) {
              photo_files.push(data.id);
            }
          });

          mutate({ ...data, video_files, photo_files }, handleSuccess);
        })
        .finally(() => setIsFilesLoading(false));
    } else {
      mutate(data, handleSuccess);
    }
  };

  const shouldExpand = Boolean(
    (workerStars && workerStars <= 4) || (serviceStars && serviceStars <= 4)
  );

  const text =
    (workerStars && workerStars <= 3) || (serviceStars && serviceStars <= 3)
      ? "Пожалуйста, расскажите, что вам не понравилось"
      : "Пожалуйста, расскажите, что мы можем улучшить";

  const commentSizeError = comment.length > 600;

  if (issue?.is_feedback_left) return <Navigate replace to={`view${document.location.search}`} />;

  if (error)
    return <Navigate to={`error?error=${(error?.response?.data as any)?.error?.message}`} />;

  return (
    <div className="pt-[20px] md:pt-[50px]">
      <Card className="text-center">
        {isLoading ? (
          <Skeleton>
            <Title>Отзыв по заявке №3333</Title>
          </Skeleton>
        ) : (
          <Title>Отзыв по заявке №{issue?.oid}</Title>
        )}
      </Card>

      <Card className="flex flex-col gap-[15px] items-center mt-[20px]">
        <div className="flex items-center justify-center gap-[15px] w-full">
          <Avatar src={issue?.worker.profile_photo_file.url} />
          <span className="text-[14px] md:text-[20px] font-medium">Оцените работу мастера</span>
        </div>
        <Rating onValueChange={(rate) => setWorkerStars(rate)} />

        <div className="flex items-center justify-center gap-[15px] w-full">
          <Avatar src={emptyAvatar} />
          <span className="text-[14px] md:text-[20px] font-medium">Оцените работу сервиса</span>
        </div>
        <Rating onValueChange={(rate) => setServiceStars(rate)} />

        {shouldExpand && (
          <>
            <div className="w-full">
              <span className="text-[14px] md:text-[16px] font-medium">{text}</span>
            </div>

            <div className="w-full">
              <div className="flex flex-wrap gap-[10px]">
                {reasons?.map((item) => (
                  <Tag key={item.id} onClick={() => handleTagClick(item)}>
                    {item.name}
                  </Tag>
                ))}
              </div>
            </div>

            <div className="w-full">
              <span className="text-[14px] md:text-[16px] font-medium">
                Комментарии и предложения
              </span>
            </div>

            <div className="w-full relative">
              <Textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Введите комментарий..."
              />
              <input
                onChange={(e) => {
                  //@ts-ignore
                  setFiles((p) => {
                    const files = [];

                    for (let elem of p || []) {
                      files.push(elem);
                    }

                    for (let elem of e.target.files || []) {
                      files.push(elem);
                    }

                    return files;
                  });
                }}
                accept="image/*, video/*"
                id="photo"
                type="file"
                multiple
                className="hidden"
              />
              <label htmlFor="photo">
                <img
                  className="absolute bottom-3 right-3 cursor-pointer"
                  src={camera}
                  alt="camera"
                />
              </label>
              <span
                className={cn(
                  "absolute bottom-[-22px] right-0",
                  commentSizeError && "text-red-500"
                )}
              >
                {comment.length + "/600"}
              </span>
            </div>

            <ScrollContainer className="w-full scroll-container py-[8px]" hideScrollbars={false}>
              <div className="flex gap-[10px]">
                {files?.map((file) => (
                  <Preview onRemoveClick={handleRemoveImgClick} key={file.name} file={file} />
                )) || <></>}
              </div>
            </ScrollContainer>
          </>
        )}

        <Button
          disabled={!Boolean(workerStars && serviceStars) || commentSizeError}
          className="w-full max-w-[360px]"
          onClick={handleSend}
          loading={isFeedbackLoading || isFilesLoading}
        >
          Отправить
        </Button>
        {commentSizeError && (
          <span className="text-[14px] text-red-500">Превышено количество символов</span>
        )}
      </Card>
    </div>
  );
};
