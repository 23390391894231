import { FC, HTMLAttributes, PropsWithChildren } from "react";
import cn from "classnames";

type Props = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

export const Card: FC<Props> = ({ children, className, ...props }) => {
  return (
    <div
      {...props}
      className={cn(
        className,
        "bg-white rounded-[8px] px-[20px] py-[20px] md:px-[60px] md:py-[30px] shadow-card"
      )}
    >
      {children}
    </div>
  );
};
