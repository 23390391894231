import axios from "axios";

export const baseApi = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    "Content-Type": "application/json",
    "x-service-name": "mytech-admin-ui",
  },
});

axios.interceptors.request.use((req) => {
  req.headers["x-request-id"] = Math.random();

  return req;
});
