import { FC, PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  onOverlayClick: () => void;
}>;

export const Overlay: FC<Props> = ({ children, onOverlayClick }) => {
  return (
    <div
      onClick={(e) => e.target === e.currentTarget && onOverlayClick()}
      className="fixed top-0 bottom-0 left-0 right-0 bg-[#00000060] flex justify-center items-center z-10 px-[10px]"
    >
      {children}
    </div>
  );
};
