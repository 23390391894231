import enable from "shared/assets/icons/enable-star.svg";
import disable from "shared/assets/icons/disable-star.svg";
import { FC, HTMLAttributes, useEffect, useMemo, useState } from "react";
import cn from "classnames";

const defaultStars = [disable, disable, disable, disable, disable];

type Props = {
  value?: number;
  onValueChange?: (rate: number) => void;
} & HTMLAttributes<HTMLDivElement>;

export const Rating: FC<Props> = ({ value, onValueChange, className, ...props }) => {
  const [active, setActive] = useState(value || 0);

  useEffect(() => {
    !active && value && setActive(value);
  }, [active, value]);

  const stars = useMemo(
    () => defaultStars.map((_, idx) => (idx + 1 <= active ? enable : disable)),
    [active]
  );

  return (
    <div {...props} className={cn(className, "flex items-center gap-[10px]")}>
      {stars.map((src, idx) => (
        <img
          key={idx}
          className="cursor-pointer"
          src={src}
          alt="star"
          onClick={() => {
            if (!onValueChange) return;
            setActive(idx + 1);
            onValueChange(idx + 1);
          }}
        />
      ))}
    </div>
  );
};
