import cn from "classnames";
import { FC, PropsWithChildren, useState } from "react";

type Props = PropsWithChildren<{
  onClick?: () => void;
}>;

export const Tag: FC<Props> = ({ children, onClick }) => {
  const [isActive, setActive] = useState(false);

  const active = isActive ? "bg-lightBlue" : "bg-lightGrey";
  const text = isActive ? "text-white" : "text-blueGrey";

  const handleClick = () => {
    if (!onClick) return;
    setActive((p) => !p);
    onClick();
  };

  return (
    <span
      onClick={handleClick}
      className={cn(
        active,
        text,
        "px-[8px] py-[3px] md:px-[10px] md:py-[5px] rounded-[16px] text-[10px] md:text-[14px] cursor-pointer select-none"
      )}
    >
      {children}
    </span>
  );
};
